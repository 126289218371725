.services-block .sub-title{
    color: #bababa;
}
.services-block .block-title{
    color: #0E2D4A;
}
.services-icon{
    font-size: 42px;
    color: #ffb600;
}
.services-detail{
    width: 75%;
    margin: auto;
}
.react-multi-carousel-item{
    border-right: 2px solid #ffb600;
    border-left: 2px solid #ffb600;
    padding: 1rem 0 4rem 0rem;
    cursor: pointer
}
.service-name{
    font-size: 19px;
}
.services-block-div li{
    list-style-type: none;
}
.service-btn-footer{
    position: absolute;
    bottom: 0;
    padding-bottom: 1rem;
}
.service-btn{
    background-color: #FFB600!important;
    border: 2px solid #FFB600;
    color:#fff;
    font-weight: 500;
}
.service-btn:hover{
    background-color: #fff!important;
    color: #FFB600!important;
    border:2px solid #FFB600!important;

}
.react-multi-carousel-track {
    border-top: 2px solid #F3F3F3;
    border-bottom: 2px solid #F3F3F3;
}
.ant-btn-primary{
    background-color: #FFB600;
    border: 1px solid #FFB600;
}
.ant-btn-primary:not(:disabled):hover{
    color: #FFB600;
    background-color: #ffffff;
}