
.callback-main .block-title{
    color:#ffffff;
}
.callback-input{
    width: 100%;
    height: 44px;
    line-height: 44px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #fff;
    transition: all 0.5s ease-in-out;
    box-shadow: none !important;
    background: transparent;
    margin-bottom: 30px;
    outline: 0 !important;
    padding: 6px 12px;
    color: #fff!important;
}
.callback-number .ant-input{
    line-height: 31px;
    border-radius: 0;
    border: 1px solid #0E2D4A;
    transition: all 0.5s ease-in-out;
    box-shadow: none !important;
    background: transparent;
    outline: 0 !important;
    padding: 6px 12px;
}
.callback-input::placeholder {
    color: #fff!important;
}
.callback-input input::placeholder {
    color: #fff!important;
}
.submit-for-callback{
    background: #FFB600;
    height: 40px;
    border-radius: 5px;
    font-weight: 600;
}
.callback-icon{
    font-size: 18px;
    color: #ffb600;
}
.callback-info-detail{
    color: #fff;
    font-weight: 500;
    padding: 0 10px;
    font-size: 15px;
}
.callback-detail{
    max-width: 1000px!important;
    margin: auto;
}
.call-back-bg{
    background-image: url("../../Images/background.webp");
    padding: 2rem;
    background-repeat: no-repeat;
    background-size: cover;
}

.callback-main {
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-image: url('../../Images/Contact-us.jpg');
    background-size: 100% 100%;
    background-attachment: fixed;
    background-repeat: no-repeat;
}

.callback-main::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgb(5 18 29 / 45%);
}
.ant-input-group .ant-input-group-addon{
    border: 1px solid #0A2C4E
}
.ask-for-callback{
    position: relative;
}
.callback-input input {
    color: #ffffff!important;
}
.ant-input ::placeholder {
    color: #fff;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fff;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #fff;
}

@media only screen and (max-width: 768px) {
    .callback-main {
        background-size: cover;
        background-position: center;
    }
}