.counter-block{
    background-color: #0E2D4A;
}
.counter{
    color: #fff;
}
.counter-number{
    font-size: 45px;
    font-weight: 700;
    padding: 10px;
}
.counter-detail{
    font-weight: 500;
}