
.strategy-bg {
    position: relative;
    align-items: center;
    justify-content: center;
    background-image: url('../../Images/home5-service-img-3-480x460.jpg');
    margin: auto;
    cursor: pointer;
}
.str-detail{
    background-color: rgb(28 40 51 / 87%);
    height: -webkit-fill-available;
    height: 320px;
}
.strategy-bg p{
    color: #fff;
    padding: 0.5rem 2rem 2rem 2rem;
    text-align: justify;

}
.strategy-header{
    color: #FFB600!important;
    font-weight: 700;
    text-align: center!important;
    padding-top: 1.5rem!important;
}
.str-detail:hover{
    background-color: rgb(255 182 0 / 87%)!important;

}
.str-detail:hover>.strategy-header, .str-detail:hover>.vision-header, .str-detail:hover>.mission-header{
    color: #fff!important;
}

.strategy-txt{
    height: inherit;
}

/* mission */


.mission-bg {
    position: relative;
    align-items: center;
    justify-content: center;
    background-image: url('../../Images/home5-service-img-1-480x460.jpg');
    margin: auto;
    cursor: pointer;
}

.mission-bg p{
    color: #fff;
    padding: 0.5rem 2rem 2rem 2rem;
    text-align: justify;
}

.mission-bg:hover> .mission-header, .mission-bg:hover> .mission-txt{
    background-color: rgb(255 182 0 / 87%)!important;
    color: #fff!important;
}
.mission-txt{
    height: inherit;
}
.mission-header{
    color: #FFB600!important;
    font-weight: 700;
    text-align: center!important;
    padding-top: 1.5rem!important;
}
/* vision */


.vision-bg {
    position: relative;
    align-items: center;
    justify-content: center;
    background-image: url('../../Images/home5-service-img-2-480x460.jpg');
    margin: auto;
    cursor: pointer;
}

.vision-bg p{
    color: #fff;
    padding: 0.5rem 2rem 2rem 2rem;
    text-align: justify;
}
.vision-header{
    color: #FFB600!important;
    font-weight: 700;
    text-align: center!important;
    padding-top: 1.5rem!important;
}
.vision-bg:hover> .vision-header, .vision-bg:hover> .vision-txt{
    background-color: rgb(255 182 0 / 87%)!important;
    color: #fff!important;
}
.vision-txt{
    height: inherit;
}

@media only screen and (max-width:1400px){
    .str-detail{
        height: 400px;
    }
}
