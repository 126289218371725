/* first navbar */
* {
    font-family: serif;
}
.spinner-div {
    position: relative;
    height: 100vh;
}
.spinner-div .ant-spin {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.header-bar {
    position: relative;
    height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-image: url('../../Images/accountant-calculating-profit-with-financial-analysis-graphs.jpg');
    background-size: cover;
    background-attachment: fixed;
}

.header-bar::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgb(5 18 29 / 55%);
}
.navbar-details{
    color: rgba(255, 255, 255, 0.6) !important;
    font-family: Poppins, Arial, sans-serif;
    font-size: 14px!important;
}
.navbar-details:hover, .font-icons-social:hover{
    color:#fff!important;
}
.font-icons{
    color: #FFB600;
    padding: 0 5px;
}
.font-icons-social{
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    padding: 8px 10px;
}
.select-lang div{
    background: rgba(255, 255, 255, 0.9)!important;
}
.selected-language{
    padding: 0 5px;
}

/* second navbar */

.logo-img img{
    height: 120px;
}
.second-nav{
    background: rgb(255,255,255, 0.8);
    padding: 0!important;
}
.second-nav .navbar-nav{
    min-height: 100px;
    align-items: center;
}
.second-nav .nav-link{
    color: #FFB600;
    font-weight: 900;
    font-size: 18px;
}
.second-nav .nav-link:hover{
    color: #ffb600;
}
.second-nav .nav-link:after {
    display:block;
    content: '';
    border-bottom: solid 6px #ffb600;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    position: relative;
    top: 34px;

}
.second-nav .nav-link:hover:after {
    transform: scaleX(1);
}

.navbar-nav .nav-link.active, .navbar-nav .show>.nav-link{
    color: #0E2D4A;
}
/* main moto */
.main-page-detail{
    max-width: 458px;
}
.main-moto-texts{
    z-index: 5;
    white-space: nowrap;
    font-size: 17px;
    line-height: 17px;
    font-weight: 300;
    color: rgb(255, 255, 255);
    font-family: Poppins;
    visibility: inherit;
    transition: none 0s ease 0s;
    text-align: inherit;
    border-width: 0px;
    margin: 0px;
    padding: 0px;
    letter-spacing: 0px;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
}
.animation-text {
    font-family: "Montserrat Medium";
    text-align: center;
    transform: scale(0.94);
    line-height: 1.2;
}

.animation1{
    animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
.animation2{
    animation: scale 4s forwards cubic-bezier(0.7, 1.3, 0.89, 2);
}
.animation3{
    animation: scale 5s forwards cubic-bezier(0.9, 1.7, 0.89, 3);
}
@keyframes scale {
    0% {
        opacity: 0;
        transform: rotateX(90deg);
    }
    50% {
        opacity: 1;
        transform: rotateX(720deg);
    }
    100% {
        /* animate nothing to pause animation at the end */
        opacity: 1;
        transform: rotateX(720deg);
    }
}
.animation-txt-detail {
    display: inline-block;
    opacity: 0;
    filter: blur(4px);
}

.animation-txt-detail {
    animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
    padding: 5px;
}

@keyframes fade-in {
    100% {
        opacity: 1;
        filter: blur(0);
    }
}
.animation-txt-detail{
    color: #fff;
}
.main-moto{
    font-weight: 900;
}
.main-detail-btn{
    margin: 5px;
}
.quote-btn{
    background: #FFB600!important;
    border: 1px solid #FFB600!important;
}
.quote-btn:hover{
    background: rgba(255, 255, 255, 0.6)!important;
    border: 1px solid  rgba(255, 255, 255, 0.6)!important;
    color: black!important;
}
.learn-more-btn{
    background:transparent!important;
    border: 1px solid rgba(255, 255, 255, 0.6) !important ;
}
.learn-more-btn:hover{
    background:#FFB600!important;
    border: 1px solid #FFB600!important ;
}

.line {
    width: 100%;
    overflow: hidden;
    padding: 0;
    margin-bottom: 16px;
}
.moving-forward{
    margin: auto;
    color: #fff;
}
.lineUp {
    animation: 2s anim-lineUp ease-out;
}
@keyframes anim-lineUp {
    0% {
        opacity: 0;
        transform: translateY(80%);
    }
    20% {
        opacity: 0;
    }
    50% {
        opacity: 1;
        transform: translateY(0%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}
.animate-character
{
    text-transform: uppercase;
    background-image: linear-gradient(
            -225deg,
            #FFB600 0%,
            #fff 29%,
            #4E6680 67%,
            #231557 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textClip 2s linear;
    display: inline-block;
    font-size: 50px;
}

@keyframes textClip {
    to {
        background-position: 200% center;
    }
}
.header-moto{
    position: relative;
}
.arrow-up-icon{
    transition: all 0.5s ease-in-out;
    color: #ffb600;
    display: block;
    font-size: 20px;
    width: 27px;
    height: 27px;
    padding: 20px;
    border: 2px solid #ffb600;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
}

@media only screen and (max-width: 1200px){
    .second-nav .nav-link{
        font-size: 16px;
    }

}

@media only screen and (max-width: 992px){
    .second-nav .nav-link:after{
        border-bottom: none;
    }

}
