.our-team-main{
    text-align: center;
    padding: 100px 0 100px;
    background-position: center top;
    background-repeat: no-repeat;
    position: relative;
    -webkit-background-size: cover;
    background-size: cover;
    margin: 0;
    border: none;
    background-color: #0E2D4A;
}

.our-team-main h1{
    color:white;
    font-size: 60px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0;
    line-height: 1;
}
.team-title{
    position: relative;
    z-index: 1;
}
.team-member-detail{
    text-align: center;
    padding-top: 30px;
    background-color: #f3ecec;
}
.teammate-name{
    transition: 0.5s;
    color: #0a2c4e;
    text-decoration: none;
    font-weight: 500;
}
.teammate-position{
    font-size: 12px;
    color: #727272;
    font-weight: 600;
    text-transform: uppercase;
    display: block;
    line-height: 1;
}
.teammate-info{
    margin: 0;
    list-style: none;
    text-align: center;
    background-color: #0a2c4e;
    padding: 20px;
    height: 105px;
}
.teammate-info p{
    color: #fff;
    font-size: 14px;
    transition: all 0.5s;
}
.teammate-img{
    height: 340px;
    object-fit: cover;
    object-position: top
}