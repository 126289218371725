.partner-image-header{
    background-image: url("../../Images/accounting.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}
.title-design{
    display: block;
    width: 70px;
    height: 25px;
    border: 4px solid #ffb600;
    border-bottom: none;
    margin: auto;
}
.block-title{
    font-size: 38px;
    font-weight: 700;
    color: #222;
    line-height: 1;
}
.sub-title{
    font-size: 13px;
    color: #979797;
    line-height: 1;
    margin-bottom: 20px;
}
.bottom-title{
    display: block;
    width: 70px;
    height: 4px;
    background-color: #ffb600;
    margin: auto;
}

/* icons and details */

.about-icons{
    transition: all 0.5s ease-in-out;
    color: #ffb600;
    display: block;
    width: 27px;
    height: 27px;
    padding: 20px;
    border: 2px solid #ffb600;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
}
.about-icons:hover{
    color:#fff;
    background-color: #ffb600;
}
.about-title{
    color: #222;
    font-size: 18px;
    font-weight: 600;
    margin-top: 30px;
    line-height: 1;
}