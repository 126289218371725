.lets-count-btn:hover{
    color: #fff!important;
}
.navbar.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;

}
.navbar{
    background-color: #ffffff;
}
/*@media only screen and (max-width: 992px){*/
/*    .nav-titles a{*/
/*        font-size: 11px!important;*/
/*    }*/
/*}*/