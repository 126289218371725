.footer-small-line{
    left: 0;
    bottom: 0;
    background-color: #ffb600;
    width: 30px;
    height: 3px;
}
.footer{
    background: #0A2C4E;
    padding: 3rem;
}
.footer-title{
    color: #ffffff;
}
.footer-about-us{
    color: #BABAAA;
    padding-top: 10px;
}
.footer-icon{
    display: block;
    width: 44px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    margin-right: 5px;
    font-size: 16px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    cursor: pointer;
}
.footer-icon a{
    color: #fff
}
.footer-icon a:hover{
    color: #fff
}
.footer-icon:hover{
    background-color: #ffb600;
}
.footer-contact span, .footer-contact svg{
    color: #BABAAA;
}
.footer-links{
    color: #BABAAA;
    text-decoration: none;
}
.footer-links:hover{
    color: #FFB600!important;
}
.small-footer{
    background-color: #01283B;
    padding-top: 15px;
    text-align: center;
}
.small-footer p{
    color: #BABAAA;
    padding:15px;
    margin: 0;
}
.footer-third-column{
    padding-top: 10px;
}